import React from 'react'
import { Link, graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { Flex, Box } from 'rebass'
import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import Heading from '../components/elements/Heading'
import theme from '../themes'

const StyledNotFoundPage = styled.div`
  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }

    &:active {
      color: ${props => props.theme.colors.linkActive};
    }
  }

  > .content {
    height: calc(100vh - 220px);
    > ${Box} {
      max-width: 1168px;
    }
  }
`

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Helmet />
    <StyledNotFoundPage>
      <Header hideLinks />
      <Flex className="content" mt={[4, null, null, 6]}>
        <Box mx="auto" my={[6]} px={[4, null, null, null, 0]}>
          <Heading pt={[6]} pb={[4]}>
            These aren't the pages you're looking for
          </Heading>
          <Link to="/">Back to Home</Link>
        </Box>
      </Flex>
      <Footer />
    </StyledNotFoundPage>
  </ThemeProvider>
)

export default NotFoundPage

export const query = graphql`
  {
    allContentfulSections(sort: { fields: [order] }) {
      edges {
        node {
          name
          sectionHeadline
          sectionBodyCopy {
            sectionBodyCopy
          }
          sectionContent {
            __typename
          }
        }
      }
    }
  }
`
